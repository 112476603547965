.news {
	&-articlesListItem:not(:last-child) {
		padding-bottom: 20px;
		border-bottom: 1px solid var(--blue10);
	}

	&-title {
		font-family: var(--futura);
		border-bottom: 1px solid var(--blue10);
		font-size: 24px;
		margin: 0 10px 40px;
		padding-bottom: 10px;
		position: relative;
		text-transform: uppercase;
	}

	&-articlesList {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 20px;
		padding: 0 10px;
		margin-bottom: 50px;
	}

	&-articlesSeeMore {
		display: flex;
		justify-content: center;
		font-family: var(--futura);
		font-size: 15px;
	}

	.teaserCard {
		align-items: flex-start;
	}
}

@media (--media-desktop) {
	.news {
		&-articlesListItem:not(:last-child) {
			padding-bottom: 0;
			border-bottom: none;
		}

		&-title {
			margin: 0 0 40px;
		}

		&-articlesList {
			display: grid;
			grid-template-columns: repeat(3, minmax(0, 1fr));
			grid-column-gap: 15px;
			padding: 0;
		}
	}
}
