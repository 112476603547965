.countdown {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column wrap;
	gap: 15px 2px;

	&-numbers {
		display: flex;
	}

	.digits {
		width: 50px;
		height: 50px;
		display: grid;
		place-content: center;
		position: relative;
		padding-bottom: 7px;
		font-weight: 800;
		font-size: 25px;

		&::before {
			content: attr(data-type);
			position: absolute;
			font-size: 10px;
			font-weight: 500;
			bottom: 2px;
			left: 50%;
			transform: translateX(-50%);
		}

		&:first-child {
			margin-right: 10px;
		}
	}

	&.expired {
		display: flex;
		align-items: center;
	}

	&-expired {
		color: var(--red);
		background-color: var(--pink30);
		text-align: center;
		font-weight: 300;
		height: 50px;
		display: flex;
		align-items: center;
		font-size: 14px;
		border-radius: 3px;
		padding: 10px;
	}
}
