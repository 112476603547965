.hero {
	&.event {
		position: relative;
		margin-bottom: 50px;

		&-countdown {
			position: absolute;
			bottom: -115px;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			background-color: var(--white);
			padding: 0 20px;

			.countdown .digits {
				color: var(--black);
				width: 35px;
				height: 35px;
				font-size: 18px;

				&::before {
					font-size: 7px;
				}
			}
		}
	}

	&.marginBottom {
		margin-bottom: 50px;
	}

	&-title {
		font-size: 30px;
		text-align: center;
		text-transform: uppercase;
		font-family: var(--futura);
		font-weight: 700;
		padding: 0 10px;
		display: flex;
		justify-content: center;

		h1 {
			text-align: center;
			margin-bottom: 20px;
		}
	}

	&-releaseNumber {
		font-size: 16px;
		font-family: var(--futura);
		text-align: center;
		position: relative;
		width: 50px;
		margin: 0 auto;
		height: 50px;
		display: flex;
		align-items: center;
		background-color: var(--black);
		color: var(--white);
		justify-content: center;
		border-radius: 50%;
	}

	&-image {
		position: relative;
		margin-bottom: 5px;

		.pictureGenerator {
			border-radius: 0;
		}
	}

	&-text {
		text-align: right;
		padding-right: 5px;
		margin-bottom: 30px;
		font-size: 12px;
	}

	&-textLink {
		color: var(--black);
		background: linear-gradient(0deg, var(--black), var(--black)) no-repeat right bottom / 0 7%;
		transition: background-size 350ms;

		&:hover {
			background-size: 100% 7%;
			background-position-x: left;
		}
	}
}

@media (--media-tablet) {
	.hero {
		&.event {
			margin-bottom: 70px;

			&-countdown {
				.countdown .digits {
					width: 50px;
					height: 50px;
					font-size: 25px;

					&::before {
						font-size: 10px;
					}
				}
			}
		}
	}
}

@media (--media-desktop) {
	.hero {
		&-title {
			font-size: 70px;
			line-height: 72px;
			text-align: left;
			padding: 0;
		}

		&-releaseNumber {
			font-size: 20px;
			font-family: var(--futura);
			text-align: center;
			position: relative;
			width: 70px;
			height: 70px;
			margin: 0 auto;
		}

		&-text {
			font-size: 14px;
		}
	}
}
