.teaserCard {
	display: grid;
	grid-template-columns: 0.7fr 1fr;
	position: relative;

	/* align-items: flex-start; */

	&.row-display {
		display: flex;
		flex-direction: column;
	}

	&.column-display {
		align-items: flex-start;
	}

	&-infosTitle {
		text-transform: uppercase;
		font-size: 16px;
		font-family: var(--futura);
		color: var(--black);
		word-break: break-word;
	}

	&-infos {
		padding: 0 10px;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	&-infosDetails {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		font-size: 10px;
		gap: 5px;
	}

	&-image {
		position: relative;
		overflow: hidden;
		box-shadow: var(--boxShadow);
		width: 100%;

		&.radius {
			border-radius: 4px;
			overflow: hidden;
		}
	}

	&-imageIcon {
		position: absolute;
		top: 10px;
		height: auto;
		display: flex;
		justify-content: flex-end;
		z-index: 2;
		pointer-events: none;
		right: 10px;
		background: var(--gray40);
		padding: 5px;

		svg {
			width: 30px;
			height: 30px;
			fill: var(--white80);
		}
	}

	&-imageReadMoreOverlay,
	&-imageReadMoreText {
		display: none;
	}

	&-infosDetailsAvatar {
		width: 20px;
		height: 20px;
		overflow: hidden;
		border-radius: 50%;
		margin-right: 8px;
	}

	&-infosText {
		font-size: 12px;
		word-break: break-word;
		font-family: var(--poppins);
	}

	&-infosDetailsCategory {
		font-family: var(--futura);
		text-transform: uppercase;
		color: var(--pinkFFU);
		font-weight: 500;
		letter-spacing: 1.5px;
	}

	&-infosDetailsAuthor {
		display: grid;
		grid-template-columns: 20px 1fr;
		grid-column-gap: 5px;
		align-items: center;

		.pictureGenerator {
			border-radius: 50%;
		}
	}

	&-infosDetailsAuthorAvatar {
		width: 20px;
		height: 20px;
	}

	&.sidebarDisplay {
		.teaserCard-infosTitle {
			order: 1;
			font-size: 13px;
		}

		.teaserCard-imageIcon {
			top: 2px;
			right: 2px;
		}

		.teaserCard-infos {
			gap: 0;
			padding-left: 10px;
			padding-right: 0;
		}

		.teaserCard-imageReadMoreText {
			width: 100%;
			font-size: 11px;
			text-align: center;
		}

		.teaserCard-infosDetails {
			margin-bottom: 0;
			order: 0;
		}
	}
}

@media (--media-desktop) {
	.teaserCard {
		&:not(.column-display) {
			display: flex;
			flex-direction: column;
			gap: 10px;
		}

		&-imageReadMoreOverlay {
			display: block;
			font-family: var(--futura);
			position: absolute;
			z-index: 1;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			visibility: hidden;
			transition:
				opacity 0.3s,
				visibility 0.3s;
			color: var(--white);
			text-transform: uppercase;
			background-color: #000;
		}

		&-imageReadMoreText {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: block;
			font-family: var(--futura);
			position: absolute;
			z-index: 1;
			opacity: 0;
			visibility: hidden;
			transition:
				opacity 0.3s,
				visibility 0.3s;
			color: var(--white);
			text-transform: uppercase;
		}

		&-infosTitle {
			font-size: 20px;
		}

		&-infosDetails {
			font-size: 10px;
			margin-bottom: 15px;
		}

		&-infosText {
			display: block;
			font-size: 14px;
			word-break: break-word;
			font-family: var(--poppins);
		}

		.underline {
			background: linear-gradient(0deg, var(--black), var(--black)) no-repeat right bottom / 0
				7%;
			transition: background-size 350ms;
		}

		&[data-wide-target]:hover {
			.underline {
				background-size: 100% 7%;
				background-position-x: left;
			}

			.pictureGenerator-image {
				transform: scale(1.05);
			}

			.teaserCard-imageReadMoreOverlay {
				visibility: visible;
				opacity: 0.7;
			}

			.teaserCard-imageReadMoreText {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
