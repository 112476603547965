.diptyque {
	width: 250px;
	height: 245px;
	position: relative;
	display: flex;
	padding: 0 0 4px 90px;
	margin: 0 auto;
	justify-content: flex-start;

	&-imageTop,
	&-imageBelow,
	&-imageMiddle {
		border: 6px solid var(--white);
		position: absolute;
		box-shadow: var(--boxShadow);
	}

	&-imageTop {
		width: 168px;
		right: 0;
		z-index: 1;
	}

	&-imageBelow {
		left: 20px;
		transform: rotate(-12deg);
		width: 140px;
		top: 20px;
	}

	&-imageCredit {
		align-self: flex-end;
		font-size: 10px;
		position: absolute;
		z-index: 2;
		color: var(--white);
	}
}
