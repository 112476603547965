.home {
	display: flex;
	flex-direction: column;
	place-content: center center;
	max-width: var(--containerMaxWidth);
	margin: 0 auto;
	padding-bottom: 80px;

	&-hero {
		padding-bottom: 50px;
		position: relative;
	}

	&-separator {
		margin: 0 10px 50px;
	}

	&-news,
	&-interviews,
	&-series,
	&-words {
		margin-bottom: 80px;
	}

	&-words {
		margin-left: 10px;
		margin-right: 10px;
	}

	&-sidebar {
		display: none;
	}
}

@media (--media-desktop) {
	.home {
		font-size: 15px;
		padding-top: 50px;

		&-separator {
			margin: 0 0 50px;
		}

		&-grid {
			grid-column-gap: 30px;
			column-gap: 30px;
			display: grid;
			grid-template-columns: minmax(0, 1fr) 300px;
			margin: 0 auto;
			padding-top: 30px;
			width: 100%;
		}

		&-words {
			margin: 0 0 80px;
		}

		&-sidebar {
			display: block;
		}
	}
}
