.words {
	position: relative;

	&-articles {
		background-color: var(--white);
		position: relative;
		padding: 30px 10px 90px;
		display: grid;
		grid-template-areas:
			'wordsTitle'
			'wordsCard1'
			'wordsCard2';
		border: 5px solid var(--blue10);
		grid-row-gap: 50px;
	}

	.teaserCard {
		display: flex;
		flex-direction: column;
		margin-bottom: 40px;
		gap: 10px;

		&-infosDetails {
			justify-content: center;
			align-items: center;
		}

		&-infosTitle {
			font-size: 20px;
			text-align: center;
		}

		&:first-child {
			grid-area: wordsCard1;
		}

		&:last-child {
			grid-area: wordsCard2;
		}
	}

	&-articlesTitle {
		grid-area: wordsTitle;
		text-align: center;
		font-family: var(--futura);
		font-size: 24px;
		text-transform: uppercase;
	}

	&-articlesSection {
		display: flex;
		flex-direction: column;
	}

	&-articlesSectionSeeMore {
		display: flex;
		justify-content: center;
		font-family: var(--futura);
		font-size: 15px;
		margin-top: auto;
	}
}

@media (--media-desktop) {
	.words {
		&-articles {
			display: grid;
			grid-template-areas:
				'wordsTitle wordsTitle'
				'wordsLeft wordsRight';
			grid-template-columns: 1fr 1fr;
			column-gap: 30px;
			padding: 50px 80px 90px;
		}

		.articlesSection:first-child {
			grid-area: wordsLeft;
		}

		.articlesSection:last-child {
			grid-area: wordsRight;
		}

		.teaserCard {
			padding: 0;
		}
	}
}
