.submit {
	position: relative;
	margin-bottom: 90px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	padding: 0 20px 70px;

	&::before {
		content: '';
		background-color: var(--pink20);
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 75%;
	}

	.diptyque {
		margin-bottom: 30px;
	}

	&-text {
		position: relative;
		justify-content: center;
		align-items: center;
		display: flex;
		font-size: 23px;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 30px;
		font-family: var(--futura);
	}

	&-buttonSection .cta.main {
		background-color: var(--black);
		color: var(--white);
		font-family: var(--futura);
	}
}

@media (--media-desktop) {
	.submit {
		height: 240px;
		display: grid;
		grid-template:
			'image text' 1fr
			'image button' 0.5fr / 1fr 1fr;
		padding: 20px 20px 30px;
		align-items: start;
		margin-bottom: 150px;

		&::before {
			height: 100%;
		}

		.diptyque {
			position: absolute;
			left: 30px;
			top: 30px;
		}

		&-text {
			grid-area: text;
			text-align: left;
			align-self: center;
			margin-bottom: 0;
		}

		&-buttonSection {
			grid-area: button;
		}
	}
}
