.series {
	&-articlesListItem {
		position: relative;
	}

	&-articlesListItem:not(:last-child) {
		padding-bottom: 20px;
		border-bottom: 1px solid var(--blue10);
	}

	&-headerTitle {
		font-family: var(--futura);
		text-transform: uppercase;
		position: relative;
		padding-bottom: 10px;
		font-size: 24px;
		margin: 0 10px 30px;
		width: 100%;
		border-bottom: 1px solid var(--blue10);
	}

	&-header {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 40px;
	}

	&-headerSectionsList {
		display: flex;
		gap: 15px;
		overflow-y: hidden;
		padding-left: 10px;
	}

	&-sectionsListItem {
		text-transform: uppercase;
		font-family: var(--futura);
		white-space: nowrap;
		padding: 15px 0;

		&:last-child {
			padding-right: 10px;
		}

		a {
			display: block;
			border: solid 2px var(--black);
			padding: 5px;
			transition:
				background-color 0.3s,
				color 0.3s;
		}

		&:hover a {
			background-color: var(--black);
			color: var(--white);
		}
	}

	&-articlesList {
		display: grid;
		grid-template-columns: 1fr;
		grid-row-gap: 20px;
		padding: 0 10px;
	}

	.teaserCard {
		align-items: flex-start;
	}
}

@media (--media-desktop) {
	.series {
		&-header {
			flex-wrap: nowrap;
			justify-content: space-between;
			padding-bottom: 10px;
			margin: 0 0 40px;
			border-bottom: 1px solid var(--blue10);
		}

		&-headerTitle {
			width: auto;
			margin: 0;
			padding: 0;
			border-bottom: none;
		}

		&-headerSectionsList {
			overflow: visible;
		}

		&-sectionsListItem {
			padding: 0;
			letter-spacing: 0;
			font-size: 12px;
		}

		&-articlesListItem {
			&:not(:last-child) {
				padding-bottom: 0;
				border-bottom: none;
			}
		}

		&-articlesList {
			display: grid;
			grid-template-columns: repeat(3, minmax(0, 1fr));
			grid-gap: 50px 15px;
			padding: 0;
		}
	}
}
