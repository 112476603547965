.sidebar {
	position: sticky;
	top: 80px;
	margin-bottom: 40px;

	&-title {
		font-family: var(--futura);
		font-size: 18px;
		padding-bottom: 10px;
		position: relative;
		text-transform: uppercase;
		border-bottom: none;
		margin-bottom: 8px;
	}

	&-eventHero {
		position: relative;
	}

	.hero.event,
	.marginBottom {
		margin-bottom: 0;
	}

	&-event {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-bottom: 40px;
		gap: 8px;
		border: 1px solid var(--gray40);
		padding: 10px;

		.sidebar-eventTitle {
			font-family: var(--futura);
			font-size: 18px;
			padding-bottom: 10px;
			position: relative;
			text-transform: uppercase;
			border-bottom: none;
			text-align: center;
		}

		.sidebar-eventCountdown {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			height: 106px;
		}
	}

	&-list {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-bottom: 40px;
	}

	&-subscribe {
		display: flex;
		align-items: center;
		padding: 20px 10px;
		border: 1px solid var(--gray40);
		font-family: var(--futura);

		.cta.main {
			background-color: var(--black);
			color: var(--white);
			font-size: 12px;
			font-family: inherit;
		}
	}

	&-subscribeText {
		font-size: 15px;
	}
}
