.interview {
	&-seeMore {
		display: flex;
		justify-content: center;
		font-family: var(--futura);
		font-size: 15px;
	}

	&-cardTitle {
		text-align: center;
		font-family: var(--futura);
		font-size: 35px;
		text-transform: uppercase;
		margin-bottom: 30px;
		letter-spacing: 3px;
	}

	&-cardPicture {
		.pictureGenerator {
			border-radius: 0;
		}
	}

	&-cardTitleText {
		position: relative;
		margin-bottom: 10px;
		padding-bottom: 10px;
		font-size: 24px;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			background-color: var(--blue20);
			width: 30%;
			height: 1px;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&-cardInfos {
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: var(--futura);
		color: var(--blue40);
		font-size: 11px;
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	&-cardInfosAuthorAvatar {
		width: 20px;
		height: 20px;
		overflow: hidden;
		border-radius: 50%;
		margin-right: 7px;
	}

	&-cardText {
		width: 90%;
		margin: 0 auto;
		background-color: var(--white);
		transform: translateY(-40px);
		font-size: 16px;
		padding: 20px 20px 0;
		text-align: center;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&-cardPictureReadMoreOverlay,
	&-cardPictureReadMoreText {
		display: none;
	}
}

@media (--media-desktop) {
	.interview {
		&-cardTtext {
			width: 70%;
			transform: translateY(-50px);
			display: block;
			padding: 20px;
			-webkit-line-clamp: unset;
			-webkit-box-orient: unset;
			overflow: visible;
			text-overflow: unset;
		}

		&-cardTitleText {
			&::before {
				width: 10%;
			}
		}

		&-cardPicture {
			position: relative;
			z-index: 0;
		}

		&-cardPictureReadMoreOverlay {
			display: block;
			font-family: var(--futura);
			position: absolute;
			z-index: 1;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			visibility: hidden;
			transition:
				opacity 0.3s,
				visibility 0.3s;
			color: var(--white);
			text-transform: uppercase;
			background-color: #000;
		}

		&-cardPictureReadMoreText {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: block;
			font-family: var(--futura);
			position: absolute;
			z-index: 1;
			opacity: 0;
			visibility: hidden;
			transition:
				opacity 0.3s,
				visibility 0.3s;
			color: var(--white);
			text-transform: uppercase;
		}

		.underline {
			background: linear-gradient(0deg, var(--black), var(--black)) no-repeat right bottom / 0
				7%;
			transition: background-size 350ms;
		}

		[data-wide-target]:hover {
			.underline {
				background-size: 100% 7%;
				background-position-x: left;
			}

			.pictureGenerator-image {
				transform: scale(1.05);
			}

			.interview-cardPictureReadMoreOverlay {
				visibility: visible;
				opacity: 0.7;
			}

			.interview-cardPictureReadMoreText {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
